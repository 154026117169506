import Project1 from "./Project1";
import Project2 from "./Project2";
//import Project3 from "./Project3";

function Projects() {
  return (
    <section className="projects">
      <Project1 />

      <Project2 />

      
    </section>
  );
}

export default Projects;